import React from "react";

import {
    Grid,
    Box,
    Typography,
    TextareaAutosize,
    TextField,
    createStyles
} from "@material-ui/core";

// Customizable Area Start
interface IProps {
    textLabel:string,
    labelCount: number,
    placeholder: string,
    rowsCount: number,
    value: string,
    name: string,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void,
    maxCharacterLenght: number
    width:string
    error?:string;
    customeLabel?:string;
}
// Customizable Area End

export default class CustomInput extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            {!!this.props.customeLabel && <Typography style={webStyle.offering}>{this.props.customeLabel}</Typography>}
                            {this.props.textLabel !== "" && <Typography style={webStyle.offering}>{this.props.textLabel} {this.props.labelCount + 1}</Typography>}
                            <TextField
                                variant="outlined"
                                multiline
                                maxRows={12}
                                id="outlined-multiline-static"
                                placeholder={this.props.placeholder}
                                minRows={this.props.rowsCount}
                                value={this.props.value}
                                InputProps={{
                                    style:{...webStyle.inputProps,width:this.props.width,
                                        fontStyle: this.props.value ? "normal" : "italic",
                                        color: this.props.value ? '#000' : "#94A3B8",
                                        }
                                }}
                                style={{...webStyle.inputProps1,width:this.props.width,}}
                                error={!!this.props.error}
                                name={this.props.name}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => this.props.onChange(event, this.props.labelCount)}
                            />
                            {!!this.props.error&&<Typography style={webStyle.errorValidate}>{this.props.error}</Typography>}
                            <Typography style={webStyle.characterLimit}>{this.props.value.length}/{this.props.maxCharacterLenght} characters</Typography>
                        </Box>
                    </Grid>
                </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle : any = createStyles({
    inputProps: {
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        lineHeight: '21px',
        padding: '10px',
    },
    inputProps1: {
        // padding: '10px',
    },
    characterLimit: {
        textAlign: 'end' as 'end',
        fontSize: '0.75rem',
        fontWeight: 500,
        color: '#475569',
        paddingTop: '5px'
    },
    offering: {
        fontSize: '0.87rem',
        fontWeight: 500,
        paddingLeft:"12px"
    },
    errorValidate: {
        fontFamily:"Montserrat",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.2,
        color: "#DC2626",
        marginTop: "2px",
      },
});
// Customizable Area End
