import React from "react";
// Customizable Area Start
import CustomList from "../../../components/src/CustomList.web";
import CustomInput from "../../../components/src/CustomInput.web";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Typography
} from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#475569",
        },
        secondary: {
            main: "#3A84C9"
        }
    },
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import CorporatePartnersController, {
    Props,
    configJSON,
} from "./CorporatePartnersController";

export default class CorporatePartners extends CorporatePartnersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { title, inputList, inputList2, serviceArray, showListHeader, processText,
            formikHandelChange,formikValue,formikError, name,tootipData} = this.props
        const containerStyle = { ...webStyle.mainContainer, ...(this.styleCondition(process, webStyle.servicesMainContainer, {})) }
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container style={this.styleCondition(!!showListHeader, {}, containerStyle)}>
                    <Grid item xs={12} style={{ ...webStyle.container, ...(this.styleCondition(processText, {}, webStyle.servicesContainer)) }}>
                        {!showListHeader && <CustomList
                            servicesList={serviceArray}
                            title={title}
                            modalContent={tootipData||""}
                        />}
                        {formikValue[name].map((data: any, index: any) => {
                            return (
                                <>
                                <Box key={index}>
                                    <CustomInput
                                        textLabel={""}
                                        customeLabel={inputList2[index].benefitsTitle}
                                        labelCount={index}
                                        placeholder={inputList2[index].benefitsPlaceholder}
                                        rowsCount={3}
                                        data-testid={"benefits-field-" + index}
                                        width="100%"
                                        name={`${name}.${index}.benifit`} 
                                        onChange={(
                                          e: React.ChangeEvent<HTMLTextAreaElement>
                                        ) => formikHandelChange(e, index)}
                                        value={formikValue[name]?.[index]?.benifit} 
                                        error={formikError[name]?.[index]?.benifit } 
                                        maxCharacterLenght={inputList2[0].charLength}
                                    />
                                </Box>
                                <Box style={{ ...webStyle.offeringBox, ...(this.styleCondition(processText, webStyle.processStyle, {})) }}>
                            <Grid item xs={12}>
                                <Box style={webStyle.infoBox}>
                                    <Typography style={webStyle.servicesHead}>Partners</Typography>
                                </Box>
                            </Grid>
                            {formikValue[name]?.[index]?.partner.map((data: any, innerIndex: any) => {
                                return (
                                    <Box key={innerIndex} style={this.styleCondition(processText, { width: '100%' }, { width: "32%" })}>
                                        <CustomInput
                                            textLabel={this.styleCondition(processText, "", inputList[innerIndex].orgNameTitle)}
                                            labelCount={innerIndex}
                                            placeholder={inputList[innerIndex].orgNamePlaceholder}
                                            rowsCount={1}
                                            data-testid={"orgName-field-" + innerIndex}
                                            width="100%"
                                            name={`${name}.${index}.partner.${innerIndex}.name`} 
                                            onChange={(
                                              e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => formikHandelChange(e, index,innerIndex)}
                                            value={formikValue[name]?.[index]?.partner[innerIndex]?.name} 
                                            error={formikError[name]?.[index]?.partner[innerIndex]?.name} 
                                            maxCharacterLenght={inputList[innerIndex].charLength}
                                        />
                                        <CustomInput
                                            textLabel={ inputList[innerIndex].descriptionTitle}
                                            labelCount={innerIndex}
                                            placeholder={ inputList[innerIndex].descriptionPlaceholder}
                                            rowsCount={2}
                                            data-testid={"desc-field-" + innerIndex}
                                            width="100%"
                                            name={`${name}.${index}.partner.${innerIndex}.description`} 
                                            onChange={(
                                              e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => formikHandelChange(e, index,innerIndex)}
                                            value={formikValue[name]?.[index]?.partner[innerIndex]?.description} 
                                            error={formikError[name]?.[index]?.partner[innerIndex]?.description} 
                                            maxCharacterLenght={inputList[innerIndex].charLengthBenefit}
                                        />
                                        <CustomInput
                                            textLabel={this.styleCondition(processText, "", inputList[innerIndex].benefitTitle)}
                                            labelCount={innerIndex}
                                            placeholder={inputList[innerIndex].benefitPlaceholder}
                                            rowsCount={3}
                                            data-testid={"benefit-field-" + innerIndex}
                                            width="100%"
                                            name={`${name}.${index}.partner.${innerIndex}.benifit`} 
                                            onChange={(
                                              e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => formikHandelChange(e, index,innerIndex)}
                                            value={formikValue[name]?.[index]?.partner[innerIndex]?.benifit} 
                                            error={formikError[name]?.[index]?.partner[innerIndex]?.benifit} 
                                            maxCharacterLenght={inputList[innerIndex].charLengthBenefit}
                                        />
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                                </>
                            )
                        }
                        )}
                    
                    </Grid >
                </Grid >
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        background: '#EEE7D9'
    },
    servicesMainContainer: {
        padding: '2% 0',
    },
    container: {
        background: '#fff',
        borderRadius: '28px',
        height: 'max-content',
    },
    servicesContainer: {
        padding: '32px 40px'
    },
    offeringBox: {
        marginBottom: '2%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap'
    },
    processStyle: {
        flexDirection: 'column' as 'column',
    },
    infoBox: {
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    servicesHead: {
        fontWeight: 600,
    }
}
// Customizable Area End

