Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.tacticalOutreach = "Tactical outreach";
exports.MarketingEmail = "Marketing emails";
exports.SalesEmails = "Sales emails";
exports.ProductEmails = "Product emails";
exports.onTouch = "ONE-TOUCH OUTREACHONE";
exports.singleTouch = "SINGLE TOUCH";
exports.setup = "Setup";
exports.parameters = "Parameters";
exports.nonPaying = "NON-PAYING CUSTOMERS";
exports.nonPayingSub = "NON-PAYING";
exports.userCustomer = "USERS & CUSTOMERS";
exports.multiTouch = "MULTI-TOUCH CAMPAIGNS";
exports.paying = "PAYING CUSTOMERS";
exports.apiEndPointGetCamp = "bx_block_cfdataintegrationviapromptengineering4/campaign_types?campaign_category_id=";
exports.apiEndPointAddData = "bx_block_catalogue/campaigns";
exports.postMethodType = "POST"
// Customizable Area End